import { AppStateType, ITrack, TPages } from './types';
import {createSlice} from '@reduxjs/toolkit';

const initialState: AppStateType = {
  loading: true,
  page: 'robot',
  playlist: [],
  currentIndex: 0,
  isPlaying: false,
  isShuffle: false,
  uiLock: false,
  showPlayerControls: true,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setPage: (state, { payload }: { payload: TPages }) => {
      state.page = payload;
    },
    setPlaylist: (state, { payload }: { payload: ITrack[] }) => {
      state.playlist = payload;
    },
    setTrackPlayed: (state, { payload }: { payload: { index: number, played: boolean} }) => {
      state.playlist[payload.index].played = payload.played;
      const index = payload.index;

      // Указать порядок, в котором воспроизводится трек
      if (state.playlist[index].playOrder === undefined) {
        const allPlayed = state.playlist.filter((k) => k.playOrder !== undefined);
        if (!allPlayed.length) {
          // Нет ни одного проигранного трека
          state.playlist[index].playOrder = 0;
          state.playlist[index].isLine = true; // нужно, чтобы к этому треку не рисовались линии
        } else if (allPlayed.length < state.playlist.length) {
          // Есть хотя бы 1 непроигранный трек. Назначить текущему треку playOrder
          const maxNum = Math.max(...allPlayed.map((k) => k.playOrder ?? 0));
          state.playlist[index].playOrder = maxNum + 1;
        }
      }
    },
    setCurrentIndex: (state, { payload }: { payload: number }) => {
      state.currentIndex = payload;
    },
    setIsPlaying: (state, { payload }: { payload: boolean }) => {
      state.isPlaying = payload;
    },
    setIsShuffle: (state, { payload }: { payload: boolean }) => {
      state.isShuffle = payload;
    },
    setIsLine: (state, { payload }: { payload: { index: number } }) => {
      state.playlist[payload.index].isLine = true;
    },
    setUiLock: (state, { payload }: { payload: boolean }) => {
      state.uiLock = payload;
    },
    setShowPlayerControls: (state, { payload }: { payload: boolean }) => {
      state.showPlayerControls = payload;
    },
    clearLines: (state) => {
      state.playlist = state.playlist.map((track, index) => {
        const newObj = {...track};
        if (state.currentIndex === index) {
          newObj.playOrder = 0;
          newObj.isLine = true;
          newObj.played = true;
        } else {
          delete newObj.playOrder;
          delete newObj.isLine;
          newObj.played = false;
        }
        return newObj;
      });
    },
  },
});

export const {
  setLoading,
  setPage,
  setPlaylist,
  setTrackPlayed,
  setCurrentIndex,
  setIsPlaying,
  setIsShuffle,
  setIsLine,
  setUiLock,
  setShowPlayerControls,
  clearLines,
} = appSlice.actions;

export default appSlice.reducer;
