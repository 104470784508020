import { createContext, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  IStarList,
  StarsProviderContextType,
} from './types';
import * as THREE from 'three';
import starUnplayed from '../images/player/starUnplayed.png';
import starPlayed from '../images/player/starPlayed.png';
import starSmall16 from '../images/player/starSmall16.png';
import starSmall29 from '../images/player/starSmall29.png';
import {
  CONSTELLATION_INITIAL_Z,
  CONSTELLATION_MAX_Z,
  CONSTELLATION_MIN_Z,
  CONSTELLATION_RADIUS,
  WINDOW_SIZE
} from '../constants/constants';
import useAppSelector from '../hooks/useSelector';
import { ITrack } from '../store/types';
import useAppDispatch from '../hooks/useDispatch';
import { setIsLine } from '../store/appSlice';
import triggerScene from '../utils/triggerScene';

export const StarsProviderContext = createContext<StarsProviderContextType>({
  renderer: null,
  onPlus: () => undefined,
  onMinus: () => undefined,
  onLinesClear: () => undefined,
});

interface IProps {
  children: ReactNode;
}

const StarsProvider = ({ children }: IProps) => {
  const [renderer] = useState<THREE.WebGLRenderer>(new THREE.WebGLRenderer({
    antialias: true,
  }));
  const [scene] = useState<THREE.Scene>(new THREE.Scene());
  const [camera] = useState<THREE.PerspectiveCamera>(new THREE.PerspectiveCamera(75, 1, 0.1, 1000));
  const [textureLoader] = useState<THREE.TextureLoader>(new THREE.TextureLoader());
  const [raycaster] = useState<THREE.Raycaster>(new THREE.Raycaster());
  const [vector] = useState<THREE.Vector2>(new THREE.Vector2());
  const [starList, setStarList] = useState<IStarList[]>([]);
  const [lines, setLines] = useState<THREE.Line[]>([]);

  const playlist = useAppSelector((state) => state.app.playlist);
  const isPlaying = useAppSelector((state) => state.app.isPlaying);
  const currentIndex = useAppSelector((state) => state.app.currentIndex);

  const loadedPlaylistRef = useRef(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log('playlist', playlist);
  }, [playlist]);

  const animate = () => {
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
  };

  const onPlus = () => {
    if (camera.position.z <= CONSTELLATION_MIN_Z) {
      return;
    }
    camera.position.z = camera.position.z - 2;
  };

  const onMinus = () => {
    if (camera.position.z > CONSTELLATION_MAX_Z) {
      return;
    }
    camera.position.z = camera.position.z + 2;
  };

  const onWheel = (e: WheelEvent) => {
    if (e.deltaY < 0) {
      onPlus();
    } else if (e.deltaY > 0) {
      onMinus();
    }
  };

  const loadSprite = (url: string, x: number, y: number, z: number, size: number, visible = true): THREE.Sprite => {
    const map = textureLoader.load(url);
    const material = new THREE.SpriteMaterial( { map } );
    const sprite = new THREE.Sprite(material);
    sprite.position.x = x;
    sprite.position.y = y;
    sprite.position.z = z;
    sprite.scale.set(size, size, 1);
    sprite.visible = visible;
    scene.add(sprite);
    return sprite;
  };

  const loadHitbox = (x: number, y: number, z: number): THREE.Mesh => {
    const geometry = new THREE.PlaneGeometry(1.5, 1.5);
    const material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
    const hitbox = new THREE.Mesh(geometry, material);
    hitbox.position.x = x;
    hitbox.position.y = y;
    hitbox.position.z = z;
    hitbox.visible = false;
    scene.add(hitbox);
    return hitbox;
  };

  const loadConstellation = (list: ITrack[]) => {
    // only once!
    console.log('loadConstellation once');

    const stars: IStarList[] = [];
    for (let i = 0; i < list.length; i++) {
      const theta = (Math.random() * 2 * Math.PI);
      const rad = Math.sqrt(Math.random()) * (CONSTELLATION_RADIUS / 2);
      const x = rad * Math.cos(theta);
      const y = rad * Math.sin(theta);
      const z = i * -2;
      const spriteUnplayed = loadSprite(starUnplayed, x, y, z, 1.75);
      const spritePlayed = loadSprite(starPlayed, x, y, z, 4.2, false);
      const hitbox = loadHitbox(x, y, z);
      stars.push({
        path: list[i].path,
        spritePlayed,
        spriteUnplayed,
        hitbox,
      });
    }
    setStarList(stars);

    for (let i = 0; i < 100; i++) {
      const theta = (Math.random() * 2 * Math.PI);
      const rad = Math.sqrt(Math.random()) * CONSTELLATION_RADIUS;
      const x = rad * Math.cos(theta);
      const y = rad * Math.sin(theta);
      const z = i / 4 * -1;
      loadSprite(starSmall16, x, y, z, 0.2);
    }

    for (let i = 0; i < 100; i++) {
      const theta = (Math.random() * 2 * Math.PI);
      const rad = Math.sqrt(Math.random()) * CONSTELLATION_RADIUS;
      const x = rad * Math.cos(theta);
      const y = rad * Math.sin(theta);
      const z = i / 4 * -1;
      loadSprite(starSmall29, x, y, z, 0.35);
    }
  };

  const updateSpriteRender = useCallback(() => {
    starList.forEach((k) => {
      // visibility

      const played = playlist.find((d) => d.path === k.path)?.played;
      if (played !== undefined) {
        k.spritePlayed.visible = played;
        k.spriteUnplayed.visible = !played;
      }

      // star scale

      const isCurrent = playlist[currentIndex].path === k.path;
      if (isCurrent && isPlaying) {
        k.spritePlayed.scale.set(5.5, 5.5, 1);
      } else {
        k.spritePlayed.scale.set(4.2, 4.2, 1);
      }

      // lines

      const playOrder = playlist[currentIndex].playOrder ?? 0;
      if (isCurrent && isPlaying && !playlist[currentIndex].isLine) {
        const points = [];
        const pos1 = k.spritePlayed.position;
        const previous = playlist.find((d) => d.playOrder === playOrder - 1);
        const pos2 = starList.find((x) => x.path === previous?.path)?.spritePlayed.position;
        if (pos1 && pos2) {
          console.log('add line');
          points.push(new THREE.Vector3(pos1.x, pos1.y, pos1.z));
          points.push(new THREE.Vector3(pos2.x, pos2.y, pos2.z));
          const geometry = new THREE.BufferGeometry().setFromPoints(points);
          const material = new THREE.LineBasicMaterial({
            color: 0x7c5248,
            linewidth: 1,
          });
          const line = new THREE.Line(geometry, material);
          scene.add(line);
          dispatch(setIsLine({ index: currentIndex }));
          setLines((prev) => {
            return [
              ...prev,
              line,
            ];
          });
        }
      }
    });
  }, [starList, playlist, currentIndex, isPlaying]);

  const getIntersection = useCallback((event: MouseEvent, objects: THREE.Mesh[]): THREE.Intersection[] => {
    const box = renderer.domElement.getBoundingClientRect();
    vector.x = ((event.clientX - box.left) / renderer.domElement.clientWidth) * 2 - 1;
    vector.y = -((event.clientY - box.top) / renderer.domElement.clientHeight) * 2 + 1;
    raycaster.setFromCamera(vector, camera);
    return raycaster.intersectObjects(objects);
  }, [camera]);

  const onMouseMove = useCallback((event: MouseEvent) => {
    event.preventDefault();
    const intersections = getIntersection(event, starList.map((k) => k.hitbox));
    const body = document.querySelector('body') as HTMLElement;
    body.style.cursor = intersections.length > 0 ? 'pointer' : 'default';
  }, [starList, getIntersection]);

  const onMouseClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    const intersections = getIntersection(event, starList.map((k) => k.hitbox));
    if (!intersections.length) {
      return;
    }
    const hitboxIds: number[] = intersections.map((k) => k.object.id);
    const getNextTrackIndex = (): number => {
      const path = starList.find((d) => hitboxIds.includes(d.hitbox.id))?.path;
      return playlist.findIndex((f) => f.path === path);
    };

    // При клике на звезду которая соответствует проигрываемому треку == стоп
    // При клике на новую == старт нового трека

    if (isPlaying) {
      const currentId = starList.find((k) => k.path === playlist[currentIndex].path)?.hitbox.id || -1;
      if (hitboxIds.includes(currentId)) {
        triggerScene('onEventPause');
        console.log('stop');
      } else {
        triggerScene('onEventSwitch', { playlist, index: getNextTrackIndex() });
        console.log('switch');
      }
    } else {
      triggerScene('onEventPlay', getNextTrackIndex());
      console.log('play');
    }
  }, [getIntersection, starList, isPlaying, currentIndex, playlist]);

  const onLinesClear = () => {
    lines.forEach((line) => {
      scene.remove(line);
    });
    setLines([]);
  };

  useEffect(() => {
    renderer.setSize(WINDOW_SIZE, WINDOW_SIZE);
    camera.position.z = CONSTELLATION_INITIAL_Z;
    animate();
    window.addEventListener('wheel', onWheel);

    return () => {
      window.removeEventListener('wheel', onWheel);
      renderer.dispose();
      scene.clear();
      camera.clear();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  useEffect(() => {
    window.addEventListener('mousedown', onMouseClick);

    return () => {
      window.removeEventListener('mousedown', onMouseClick);
    };
  }, [onMouseClick]);

  useEffect(() => {
    if (!loadedPlaylistRef.current && playlist?.length) {
      loadConstellation(playlist);
      loadedPlaylistRef.current = true;
    }

    if (playlist?.length && starList.length && scene && loadedPlaylistRef.current) {
      updateSpriteRender();
    }
  }, [scene, updateSpriteRender]);

  return (
    <StarsProviderContext.Provider
      value={{
        renderer,
        onPlus,
        onMinus,
        onLinesClear,
      }}
    >
      { children }
    </StarsProviderContext.Provider>
  );
};

export default StarsProvider;
