import styled from 'styled-components';
import logo from '../../images/logo.svg';
import { LogoImg } from './style';
import NoteLayout from './NoteLayout';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(/assets/images/kor_eye.jpg);
  background-repeat: no-repeat;
  background-size: cover;
`;

const PageRobot = () => {
  return (
    <Wrapper>
      <LogoImg src={logo} alt=''/>
      <NoteLayout />
    </Wrapper>
  );
};

export default PageRobot;
