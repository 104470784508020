import styled from 'styled-components';
import { PagePlayerWrapper } from './style';
import Stars from './Stars';
import Tracker from './Tracker';
import ControlPanel from './ControlPanel';
import ScaleButtons from './ScaleButtons/ScaleButtons';
import Tracklist from './Tracklist';
import UiLock from './UILock';
import NoTracksMessage from './NoTracksMessage';
import useAppSelector from '../../hooks/useSelector';
import DevButtons from './DevButtons';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;
`;

const PagePlayer = () => {
  const show = useAppSelector((state) => state.app.showPlayerControls);

  return (
    <Wrapper>
      <PagePlayerWrapper>
        {
          show && <>
            <Tracker />
            <ControlPanel />
          </>
        }
        <NoTracksMessage />
        <Tracklist />
        <ScaleButtons />
        <Stars />
        <UiLock />
        <DevButtons />
      </PagePlayerWrapper>
    </Wrapper>
  );
};

export default PagePlayer;
