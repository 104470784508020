const secondsToMinutes = (secondsFloat: number): string => {
  const integer = Math.round(secondsFloat);
  const minutes = Math.floor(integer / 60);
  const seconds = integer - minutes * 60;
  let minutesStr = String(minutes);
  if (minutesStr.length < 2) {
    minutesStr = '0' + minutesStr;
  }
  let secondsStr = String(seconds);
  if (secondsStr.length < 2) {
    secondsStr = '0' + secondsStr;
  }
  return minutesStr + ':' + secondsStr;
};

export default secondsToMinutes;
