const filterAudioData = (audioBuffer: AudioBuffer): number[] => {
  const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
  const samples = 157; // Number of samples we want to have in our final data set
  const blockSize = Math.floor(rawData.length / samples); // Number of samples in each subdivision
  const filteredData = [];
  for (let i = 0; i < samples; i++) {
    const blockStart = blockSize * i; // the location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
    }
    filteredData.push(Math.round((sum / blockSize) * 100)); // divide the sum by the block size to get the average
  }
  return filteredData;
  // maximum number = 33;
};

export default filterAudioData;
