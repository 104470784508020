import styled from 'styled-components';
import useAppSelector from '../../../hooks/useSelector';
import useAppDispatch from '../../../hooks/useDispatch';
import { clearLines, setShowPlayerControls } from '../../../store/appSlice';
import { useContext } from 'react';
import { AudioProviderContext } from '../../../providers/AudioProvider';
import { StarsProviderContext } from '../../../providers/StarsProvider';

const DevButton = styled.button`
  padding: 2px;
  opacity: 0.5;
  font-size: 8px;
  min-width: 60px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 0;
  bottom: -19px;
  z-index: 1;
`;

const DevButtons = () => {
  const show = useAppSelector((state) => state.app.showPlayerControls);
  const { audio: { muted }, onMute } = useContext(AudioProviderContext);
  const { onLinesClear } = useContext(StarsProviderContext);

  const dispatch = useAppDispatch();

  const handleClear = () => {
    onLinesClear();
    dispatch(clearLines());
  };

  const handleControls = () => {
    dispatch(setShowPlayerControls(!show));
  };

  const handleMute = () => {
    onMute(!muted);
  };

  return (
    <Wrapper>
      <DevButton
        onClick={handleClear}
        style={{ color: '#ff0000'}}
      >
        Clear
      </DevButton>
      <DevButton
        onClick={handleControls}
      >
        {show ? 'Hide' : 'Show'} controls
      </DevButton>
      <DevButton
        onClick={handleMute}
      >
        {muted ? 'Unmute' : 'Mute'}
      </DevButton>
    </Wrapper>
  );
};

export default DevButtons;
