import styled from 'styled-components';
import ControlLeft from './ControlLeft';
import ControlRight from './ControlRight';
import ControlCenter from './ControlCenter';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

const ControlPanel = () => {
  return (
    <Wrapper>
      <ControlLeft />
      <ControlCenter />
      <ControlRight />
    </Wrapper>
  );
};

export default ControlPanel;
