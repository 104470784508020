import styled from 'styled-components';
import { TrackerCloud, TrackerSpectreHatch, TrackerSpectreImageBox, TrackerThumb } from './style';
import TrackerLines from './TrackerLines';
import React, { useContext, useEffect, useRef, useState } from 'react';
import getThumbPosition from '../utils/getThumbPosition';
import { AudioProviderContext } from '../../../providers/AudioProvider';
import useAppSelector from '../../../hooks/useSelector';

const Wrapper = styled.div`
  width: 100%;
  height: 45px;
  padding: 0 12px;
  position: relative;
  display: flex;
  align-items: center;
`;

interface IProps {
  percent: number;
}

const TrackerSpectre = ({ percent }: IProps) => {
  const [left, setLeft] = useState<string>('0px');
  const spectreRef = useRef(null);

  const { duration, onSearch } = useContext(AudioProviderContext);
  const playlist = useAppSelector((state) => state.app.playlist);
  const currentIndex = useAppSelector((state) => state.app.currentIndex);

  useEffect(() => {
    if (spectreRef.current) {
      const ref = spectreRef.current as HTMLDivElement;
      const result = getThumbPosition(percent, ref.clientWidth, 12);
      setLeft(result);
    }
  }, [percent]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const pos = e.clientX - rect.left;
    const newTime = pos * duration / rect.width;
    onSearch(newTime);
  };

  return (
    <Wrapper>
      <TrackerSpectreImageBox
        ref={spectreRef}
        onClick={handleClick}
      >
        {
          playlist[currentIndex]?.waveform?.map((num, index) => <TrackerSpectreHatch
            key={index}
            height={num}
          />)
        }
      </TrackerSpectreImageBox>
      <TrackerThumb
        left={left}
      />
      <TrackerLines
        width={left}
      />
      <TrackerCloud
        width={left}
      />
    </Wrapper>
  );
};

export default TrackerSpectre;
