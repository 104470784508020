import styled from 'styled-components';

const Wrapper = styled.button`
  background: #0e0a08;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0.5px solid white;
  color: white;
  font-size: 8px;
  font-weight: 350;
  user-select: none;
`;

interface IProps {
  text: string;
  onClick?: () => void;
}

const ScaleButton = ({ text, onClick = () => undefined }: IProps) => {
  return (
    <Wrapper
      onClick={onClick}
    >
      { text }
    </Wrapper>
  );
};

export default ScaleButton;
