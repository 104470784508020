import styled from 'styled-components';
import { TrackerLine } from './style';

interface WrapperProps {
  width: string;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: ${props => props.width};
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 12px;
  //transition: width 0.1s linear;
  z-index: 2;
`;

interface IProps {
  width: string;
}

const TrackerLines = ({ width = '0px' }: IProps) => {
  return (
    <Wrapper
      width={width}
    >
      <TrackerLine bg='#8449FF'/>
      <TrackerLine />
      <TrackerLine />
      <TrackerLine bg='#C36EA5'/>
    </Wrapper>
  );
};

export default TrackerLines;
