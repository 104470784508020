import { ControlCenterCore, PlayCircle, WrapperControlCenter } from './style';
import PlayerButton from '../../../components/UI/PlayerButton';
import PreviousSvg from '../../../images/dynamic/PreviousSvg';
import PlaySvg from '../../../images/dynamic/PlaySvg';
import NextSvg from '../../../images/dynamic/NextSvg';
import playCircles from '../../../images/player/playCircles.svg';
import { useContext } from 'react';
import { AudioProviderContext } from '../../../providers/AudioProvider';
import { TRACKS } from '../../../constants/constants';
import PauseSvg from '../../../images/dynamic/PauseSvg';
import AnimatedCircles from '../AnimatedCircles';
import useAppSelector from '../../../hooks/useSelector';

const ControlCenter = () => {
  const {
    onPlay,
    onPause,
    onNext,
    onPrevious,
  } = useContext(AudioProviderContext);

  const currentIndex = useAppSelector((state) => state.app.currentIndex);
  const isPlaying = useAppSelector((state) => state.app.isPlaying);
  const disabled = useAppSelector((state) => state.app.playlist.length) === 0;

  const handlePlay = () => {
    if (isPlaying) {
      onPause();
    } else {
      onPlay();
    }
  };

  return (
    <WrapperControlCenter
      disabled={disabled}
    >
      <ControlCenterCore>
        <PlayerButton
          disabled={currentIndex === 0}
          onClick={onPrevious}
        >
          <PreviousSvg />
        </PlayerButton>
        <PlayerButton
          disabled={disabled}
          onClick={handlePlay}
        >
          {
            isPlaying
              ? <PauseSvg />
              : <PlaySvg />
          }
          {
            disabled
              ? <PlayCircle src={playCircles} alt=''/>
              : <AnimatedCircles />
          }
        </PlayerButton>
        <PlayerButton
          disabled={disabled || currentIndex === TRACKS.length - 1}
          onClick={onNext}
        >
          <NextSvg />
        </PlayerButton>
      </ControlCenterCore>
    </WrapperControlCenter>
  );
};

export default ControlCenter;
