import styled from 'styled-components';
import { NoTracksText } from './style';
import useAppSelector from '../../../hooks/useSelector';

const Wrapper = styled.div`
  width: 343px;
  height: 44px;
  position: absolute;
  left: 12px;
  top: 108px;
  border-radius: 39px;
  border: 0.5px solid #797472;
  background: #201714;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: 1;
`;

const NoTracksMessage = () => {
  const playlist = useAppSelector((state) => state.app.playlist);

  if (playlist.length) {
    return null;
  }

  return (
    <Wrapper>
      <NoTracksText>No Tracks yet, go to KORUS.co</NoTracksText>
    </Wrapper>
  );
};

export default NoTracksMessage;
