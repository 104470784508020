import {
  createContext,
  ReactNode,
  useEffect,
  useState
} from 'react';
import { TRACKS } from '../constants/constants';
import { AudioProviderContextType } from './types';
import shuffleArray from '../utils/shuffleArray';
import filterAudioData from '../utils/filterAudioData';
import useAppSelector from '../hooks/useSelector';
import { ITrack } from '../store/types';
import useAppDispatch from '../hooks/useDispatch';
import { setCurrentIndex, setIsPlaying, setIsShuffle, setPlaylist, setTrackPlayed } from '../store/appSlice';

interface IProps {
  children: ReactNode;
}

export const AudioProviderContext = createContext<AudioProviderContextType>({
  audio: new Audio(),
  onPlay: () => undefined,
  onPause: () => undefined,
  onNext: () => undefined,
  onPrevious: () => undefined,
  onShuffle: () => undefined,
  isShuffle: false,
  duration: 0,
  playingTime: 0,
  onSearch: () => undefined,
  amplitude: 0,
  onMute: () => undefined,
});

const AudioProvider = ({ children }: IProps) => {
  const [audio] = useState<HTMLAudioElement>(new Audio());
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [duration, setDuration] = useState(0);
  const [playingTime, setPlayingTime] = useState(0);
  const [amplitude, setAmplitude] = useState<number>(0);

  const playlist = useAppSelector((state) => state.app.playlist);
  const currentIndex = useAppSelector((state) => state.app.currentIndex);
  const isPlaying = useAppSelector((state) => state.app.isPlaying);
  const isShuffle = useAppSelector((state) => state.app.isShuffle);
  const dispatch = useAppDispatch();

  const loadTrack = (index: number) => {
    audio.src = playlist[index].path;
  };

  const getAudioWaveform = async (url: string): Promise<number[]> => {
    if (!audioContext) {
      return [];
    }
    return fetch(url)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(audioBuffer => filterAudioData(audioBuffer));
  };

  const loadPlaylist = async (tracks: string[]) => {
    const result: ITrack[] = [];

    for (let i = 0; i < tracks.length; i++) {
      try {
        const waveform = await getAudioWaveform(tracks[i]);
        result.push({
          number: i + 1,
          path: tracks[i],
          waveform,
          played: false,
        });
      } catch (e) {
        console.log(e);
      }
    }

    dispatch(setPlaylist(result));
  };

  const analyserLoop = (analyser: AnalyserNode) => {
    window.requestAnimationFrame(() => analyserLoop(analyser));
    const arr = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(arr);
    setAmplitude(arr[40]);
  };

  useEffect(() => {
    if (audioContext) {
      loadPlaylist(TRACKS);
    }
  }, [audioContext]);

  const onPlay = () => {
    audio.play();
    dispatch(setIsPlaying(true));
    dispatch(setTrackPlayed({ index: currentIndex, played: true }));
  };

  const onPlayEvent = (event: unknown) => {
    const index = (event as { detail: number }).detail;
    audio.play();
    dispatch(setIsPlaying(true));
    dispatch(setTrackPlayed({ index, played: true }));
    dispatch(setCurrentIndex(index));
  };

  const onSwitchEvent = (event: unknown) => {
    const { playlist: actualPlaylist, index } = (event as { detail: { playlist: ITrack[], index: number } }).detail;
    audio.src = actualPlaylist[index].path;
    audio.play();
    dispatch(setTrackPlayed({ index, played: true }));
    dispatch(setCurrentIndex(index));
  };

  const onPause = () => {
    audio.pause();
    dispatch(setIsPlaying(false));
  };

  const onPrevious = () => {
    loadTrack(currentIndex - 1);
    if (isPlaying) {
      audio.play();
      dispatch(setTrackPlayed({ index: currentIndex - 1, played: true }));
    }
    dispatch(setCurrentIndex(currentIndex - 1));
  };

  const onNext = () => {
    loadTrack(currentIndex + 1);
    if (isPlaying) {
      audio.play();
      dispatch(setTrackPlayed({ index: currentIndex + 1, played: true }));
    }
    dispatch(setCurrentIndex(currentIndex + 1));
  };

  const setNextTrackAfterEndOfCurrent = () => {
    const newIndex = currentIndex + 1;
    if (playlist[newIndex]) {
      onNext();
    } else {
      dispatch(setIsPlaying(false));
    }
  };

  useEffect(() => {
    audio.src = TRACKS[0];
    audio.onloadedmetadata = () => {
      setDuration(audio.duration);
    };
    audio.ontimeupdate = () => {
      setPlayingTime(audio.currentTime);
    };

    document.querySelector('html')?.addEventListener('click', () => {
      // 'play' button animation and saving the audio context
      const context = new AudioContext();
      const analyser = context.createAnalyser();
      const src = context.createMediaElementSource(audio);
      src.connect(analyser);
      analyser.connect(context.destination);
      analyserLoop(analyser);
      setAudioContext(context);
    }, { once: true });

    document.addEventListener('onEventPause', onPause);
    document.addEventListener('onEventPlay', onPlayEvent);
    document.addEventListener('onEventSwitch', onSwitchEvent);

    return () => {
      document.removeEventListener('onEventPause', onPause);
      document.removeEventListener('onEventPlay', onPlayEvent);
      document.removeEventListener('onEventSwitch', onSwitchEvent);
    };
  }, []);

  audio.onended = () => setNextTrackAfterEndOfCurrent();

  const onShuffle = () => {
    const newShuffle = !isShuffle;
    if (newShuffle) {
      const newArr = shuffleArray(playlist);
      dispatch(setPlaylist(newArr));
      const index = newArr.findIndex((k) => k.path === playlist[currentIndex].path);
      dispatch(setCurrentIndex(index));
    } else {
      const defaultList = [...playlist].sort((a, b) => a.number - b.number);
      dispatch(setPlaylist(defaultList));
      const index = defaultList.findIndex((k) => k.path === playlist[currentIndex].path);
      dispatch(setCurrentIndex(index));
    }
    dispatch(setIsShuffle(newShuffle));
  };

  const onSearch = (time: number) => {
    audio.currentTime = time;
  };

  const onMute = (value: boolean) => {
    audio.muted = value;
  };

  return (
    <AudioProviderContext.Provider
      value={{
        audio,
        onPlay,
        onNext,
        onPrevious,
        onPause,
        onShuffle,
        isShuffle,
        duration,
        playingTime,
        onSearch,
        amplitude,
        onMute,
      }}
    >
      { children }
    </AudioProviderContext.Provider>
  );
};

export default AudioProvider;
