export const TRACKS: string[] = [
  '/assets/audio/friendzoned-intermezzo.mp3',
  '/assets/audio/colorful-cat-striving.mp3',
  '/assets/audio/colorful-cat-hanada.mp3',
  '/assets/audio/joakim-karud-celebrate.mp3',
  '/assets/audio/pryces-grown.mp3',
  '/assets/audio/22b5c9ba229feed.mp3',
  '/assets/audio/13869_1459868658.mp3',
  '/assets/audio/551082.mp3',
  '/assets/audio/aa058435acb065d.mp3',
  '/assets/audio/audio44.mp3',
  '/assets/audio/crickets.mp3',
  '/assets/audio/dog_barking_close.mp3',
  '/assets/audio/dogs-fight-1.mp3',
  '/assets/audio/sfx-579-gym-4.mp3',
  '/assets/audio/shutter_release-.mp3',
  '/assets/audio/sitcom-laughter-applause.mp3',
  '/assets/audio/suspense.mp3',
];

export const WINDOW_SIZE = 430;
export const CONSTELLATION_RADIUS = 20;
export const CONSTELLATION_MIN_Z = 12;
export const CONSTELLATION_MAX_Z = 80;
export const CONSTELLATION_INITIAL_Z = 18;
