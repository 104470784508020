import styled from 'styled-components';

interface TimeProps {
  disabled?: boolean;
}

export const TrackerTime = styled.p<TimeProps>`
  color: ${props => props.disabled ? '#494949' : '#B5B5B5'};
  font-size: 12px;
  font-weight: 350;
  line-height: 140%;
  letter-spacing: -0.24px;
  user-select: none;
  width: 34px;
  flex-shrink: 0;
`;

export const TrackerSpectreEmpty = styled.div`
  width: 100%;
  margin: 0 12px;
  border-bottom: 2px solid #494949;
  border-radius: 8px;
  transform: translateY(-1px);
`;

interface ThumbProps {
  left: string;
}

export const TrackerThumb = styled.div<ThumbProps>`
  width: 1px;
  height: 45px;
  border-radius: 35px;
  background: #FFF;
  box-shadow: 0 0 4px 0 #FFF;
  position: absolute;
  top: 0;
  left: ${props => props.left};
  //transition: left 0.1s linear;
  z-index: 3;
`;

interface LineProps {
  bg?: string;
}

export const TrackerLine = styled.div<LineProps>`
  height: 1px;
  width: 100%;
  background: ${props => props.bg ? props.bg : '#5E5E5E'};
  opacity: ${props => props.bg ? 1 : 0.35};
`;

interface CloudProps {
  width: string;
}

export const TrackerCloud = styled.div<CloudProps>`
  border-radius: 30.5px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.35) 46.88%, rgba(255, 255, 255, 0.05) 100%);
  opacity: 0.5;
  width: ${props => props.width};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
`;

export const TrackerSpectreImageBox = styled.div`
  position: relative;
  z-index: 1;
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5px;
`;

interface HatchProps {
  height: number;
}

export const TrackerSpectreHatch = styled.div<HatchProps>`
  width: 1px;
  height: ${props => props.height + 2 + 'px'};
  border-radius: 1px;
  background: linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);
  user-select: none;
  pointer-events: none;
`;
