import styled from 'styled-components';
import Loading from '../PageLoading';
import useAppSelector from '../../hooks/useSelector';
import useAppDispatch from '../../hooks/useDispatch';
import { setLoading } from '../../store/appSlice';
import PageRobot from '../PageRobot';
import PagePlayer from '../PagePlayer';
import { ReactElement } from 'react';
import { TPages } from '../../store/types';
import { WINDOW_SIZE } from '../../constants/constants';

interface WrapperProps {
  page: TPages;
}

const Wrapper = styled.div<WrapperProps>`
  //min-width: 430px;
  //max-width: 100vw;
  //min-height: 430px;
  //max-height: 100vw;
  //width: 100vh;
  //height: 100vh;
  width: ${WINDOW_SIZE}px;
  height: ${WINDOW_SIZE}px;
  background-color: black;
  margin: 0 auto;
`;

const App = () => {
  const page = useAppSelector((state) => state.app.page);
  const loading = useAppSelector((state) => state.app.loading);
  const dispatch = useAppDispatch();

  const onClickHandler = () => {
    dispatch(setLoading(false));
  };

  const renderPage = (): ReactElement | null => {
    if (loading) {
      return <Loading />;
    }

    switch (page) {
      case 'robot': return <PageRobot />;
      case 'player' : return <PagePlayer />;
      default: return null;
    }
  };

  return (
    <Wrapper
      onClick={onClickHandler}
      page={page}
    >
      { renderPage() }
    </Wrapper>
  );
};

export default App;
