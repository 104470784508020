import { ControlLeftCore, WrapperControlLeft } from './style';
import PlayerButton from '../../../components/UI/PlayerButton';
import ShuffleSvg from '../../../images/dynamic/ShuffleSvg';
import { useContext, useState } from 'react';
import { AudioProviderContext } from '../../../providers/AudioProvider';
import useAppSelector from '../../../hooks/useSelector';

const ControlLeft = () => {
  const [hover, setHover] = useState<boolean>(false);

  const { onShuffle, isShuffle } = useContext(AudioProviderContext);
  const disabled = useAppSelector((state) => state.app.playlist.length) === 0;

  return (
    <WrapperControlLeft
      disabled={disabled}
      active={isShuffle}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      hover={hover}
      onClick={onShuffle}
    >
      <ControlLeftCore>
        <PlayerButton
          disabled={disabled}
          active={isShuffle}
          hover={hover}
        >
          <ShuffleSvg />
        </PlayerButton>
      </ControlLeftCore>
    </WrapperControlLeft>
  );
};

export default ControlLeft;
