import styled, { css } from 'styled-components';

interface WrapperProps {
  disabled?: boolean;
  active?: boolean;
  hover?: boolean;
}

export const WrapperControlLeft = styled.div<WrapperProps>`
  width: 97px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D9D9D9;
  border-radius: 5px 5px 5px 10px;
  cursor: pointer;
  
  ${props => props.hover && css`
    background: linear-gradient(90deg, rgba(255,102,92,1) 0%, rgba(91,42,200,1) 100%);
  `};

  ${props => props.disabled && css`
    background: #494949;
  `};
  
  ${props => props.active && !props.disabled && css`
    background: linear-gradient(90deg, rgba(255,102,92,1) 0%, rgba(91,42,200,1) 100%);
    
    > div {
      background: none;
    }
  `};
`;

export const WrapperControlRight = styled.div<WrapperProps>`
  width: 97px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D9D9D9;
  border-radius: 5px 5px 10px 5px;

  ${props => props.hover && css`
    background: linear-gradient(90deg, rgba(91,42,200,1) 0%, rgba(255,102,92,1) 100%);
  `};

  ${props => props.disabled && css`
    background: #494949;
  `};

  ${props => props.active && !props.disabled && css`
    &:active {
      > div {
        background: none;
      }
    }
  `};
`;

interface ControlCenterProps {
  disabled?: boolean;
}

export const WrapperControlCenter = styled.div<ControlCenterProps>`
  width: 163px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D9D9D9;
  border-radius: 27px 27px 5px 5px;

  ${props => !props.disabled ? css`
    &:hover {
      background: linear-gradient(90deg, rgba(91,42,200,1) 0%, rgba(255,102,92,1) 100%);
    }
  ` : css`
    background: #494949;
  `};
`;

const ControlCoreCss = css`
  background: #130e0c;
  width: 95px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  //transition: background 0.1s;
`;

export const ControlLeftCore = styled.div`
  ${ControlCoreCss};
  border-radius: 5px 5px 5px 10px;
`;

export const ControlRightCore = styled.div`
  ${ControlCoreCss};
  border-radius: 5px 5px 10px 5px;
`;

export const ControlCenterCore = styled.div`
  background: #130e0c;
  width: 161px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.1s;
  border-radius: 27px 27px 5px 5px;
  gap: 16px;
`;

export const ControlPanelText = styled.p`
  color: #e9e9e9;
  font-size: 10px;
  letter-spacing: -0.2px;
  line-height: 10px;
  font-weight: 400;
  font-family: 'ABC Favorit', sans-serif;
`;

export const PlayCircle = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  left: -8px;
  top: -9px;
`;
