import styled from 'styled-components';
import { TrackerTime, TrackerSpectreEmpty } from './style';
import TrackerSpectre from './TrackerSpectre';
import { useContext } from 'react';
import { AudioProviderContext } from '../../../providers/AudioProvider';
import secondsToMinutes from '../../../utils/secondsToMinutes';
import useAppSelector from '../../../hooks/useSelector';

interface WrapperProps {
  disabled?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 73px;
  border: 0.6px solid ${props => props.disabled ? '#494949' : '#D9D9D9'};
  border-radius: 5px 5px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  backdrop-filter: blur(3px);
  position: absolute;
  left: 0;
  bottom: 56px;
  z-index: 1;
`;

const Tracker = () => {
  const { duration, playingTime } = useContext(AudioProviderContext);
  const disabled = useAppSelector((state) => state.app.playlist.length) === 0;

  return (
    <Wrapper
      disabled={disabled}
    >
      <TrackerTime
        disabled={disabled}
      >
        { secondsToMinutes(playingTime) }
      </TrackerTime>

      {
        disabled
          ? <TrackerSpectreEmpty />
          : <TrackerSpectre
            percent={playingTime / duration * 100}
          />
      }

      <TrackerTime
        disabled={disabled}
      >
        { secondsToMinutes(duration) }
      </TrackerTime>
    </Wrapper>
  );
};

export default Tracker;
