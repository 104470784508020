import circleInner from '../../../images/player/circleInner.svg';
import circleCenter from '../../../images/player/circleCenter.svg';
import { useContext } from 'react';
import { AudioProviderContext } from '../../../providers/AudioProvider';
import PlayerRectRound from '../../../images/dynamic/PlayerRectRound';
import styles from './styles.module.scss';
import cn from 'classnames';

const getRx = (amplitude: number): string => {
  if (amplitude < 50) {
    return '20';
  }
  let k = (amplitude - 50) / 30;
  if (k > 7) {
    k = 7;
  }
  return String(20 - k);
};

const AnimatedCircles = () => {
  const { amplitude } = useContext(AudioProviderContext);

  return (
    <div className={styles.root}>
      <img
        src={circleInner}
        alt=''
        className={cn(
          styles.innerCore,
          { [styles.animPlay]: amplitude },
        )}
        style={{
          width: amplitude / 50 + 33 + 'px',
        }}
      />

      <img
        src={circleCenter}
        alt=''
        className={cn(
          styles.centerCore,
          { [styles.animPlay]: amplitude },
        )}
        style={{
          width: amplitude / 50 + 40 + 'px',
        }}
      />

      <PlayerRectRound
        rx={getRx(amplitude)}
        className={cn(
          styles.outerCore,
          { [styles.animPlay]: amplitude },
        )}
      />
    </div>
  );
};

export default AnimatedCircles;
