import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

interface WrapperProps {
  disabled?: boolean;
  active?: boolean;
  hover?: boolean;
  width: string;
}

const Wrapper = styled.button<WrapperProps>`
  width: ${props => props.width};
  height: 32px;
  background: none;
  position: relative;
  
  ${props => !props.disabled ? css`
    &:hover {
      svg path {
        fill: white;
      }

      p {
        color: white;
      }
    }
  ` : css`
    cursor: default;

    svg path {
      fill: #494949;
    }
    
    p {
      color: #494949;
    }
  `};
  
  ${props => (props.active || props.hover) && css`
    svg path {
      fill: white;
    }
  `};
`;

interface IProps {
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  disabled?: boolean;
  width?: string;
  active?: boolean;
  hover?: boolean;
  children: ReactNode;
}

const PlayerButton = ({
  onClick = () => undefined,
  onMouseDown = () => undefined,
  onMouseUp = () => undefined,
  onMouseOver = () => undefined,
  onMouseOut = () => undefined,
  children,
  disabled,
  active,
  hover,
  width = '32px'
}: IProps) => {
  return (
    <Wrapper
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      disabled={disabled}
      active={active}
      hover={hover}
      width={width}
    >
      { children }
    </Wrapper>
  );
};

export default PlayerButton;
