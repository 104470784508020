import styled from 'styled-components';
import ScaleButton from '../../../components/UI/ScaleButton';
import { useContext } from 'react';
import { StarsProviderContext } from '../../../providers/StarsProvider';

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  top: -6px;
  right: 0;
  z-index: 1;
`;

const ScaleButtons = () => {
  const { onMinus, onPlus } = useContext(StarsProviderContext);

  return (
    <Wrapper>
      <ScaleButton
        text='+'
        onClick={onPlus}
      />
      <ScaleButton
        text='-'
        onClick={onMinus}
      />
    </Wrapper>
  );
};

export default ScaleButtons;
