import { useContext, useEffect, useRef } from 'react';
import { StarsProviderContext } from '../../../providers/StarsProvider';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  left: -24px;
  top: -24px;
`;

const Stars = () => {
  const { renderer } = useContext(StarsProviderContext);
  const rootRef = useRef(null);

  useEffect(() => {
    if (rootRef.current && renderer) {
      (rootRef.current as HTMLElement).appendChild(renderer.domElement);
    }
  }, [renderer]);

  return (
    <Wrapper ref={rootRef}/>
  );
};

export default Stars;
