import styled from 'styled-components';
import useAppSelector from '../../../hooks/useSelector';
import { WINDOW_SIZE } from '../../../constants/constants';

const Wrapper = styled.div`
  position: absolute;
  left: -24px;
  top: -24px;
  z-index: 9999;
  width: ${WINDOW_SIZE + 'px'};
  height: ${WINDOW_SIZE + 'px'};
  //background: green;
`;

const UiLock = () => {
  const uiLock = useAppSelector((state) => state.app.uiLock);

  if (uiLock) {
    return <Wrapper />;
  }
  return null;
};

export default UiLock;
