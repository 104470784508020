import styled from 'styled-components';
import note from '../../../images/note.svg';

const Wrapper = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 8.8px;
  background: #585858;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > button {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    background: #2f2f2f;
  }
  
  &:hover {
    background: linear-gradient(245deg, #FF665C 0%, #5B2AC8 60%);
  }
  
  &:active {
    background: linear-gradient(245deg, #FF665C 0%, #5B2AC8 60%);
    
    > button {
      background: none;
    }
  }
`;

interface IProps {
  onClick?: () => void;
}

const NoteButton = ({ onClick = () => undefined }: IProps) => {
  return (
    <Wrapper>
      <button
        onClick={onClick}
      >
        <img src={note} alt='Next'/>
      </button>
    </Wrapper>
  );
};

export default NoteButton;
