import styled from 'styled-components';
import useAppSelector from '../../../hooks/useSelector';
import { useState } from 'react';
import { WINDOW_SIZE } from '../../../constants/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  gap: 4px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  z-index: 1;

  .selected {
    outline: 1px solid white;
  }
`;

const ShowButton = styled.button`
  padding: 2px;
  opacity: 0.7;
`;

const ScrollArea = styled.div`
  max-height: ${WINDOW_SIZE * 0.7 + 'px'};
  overflow-y: auto;
  padding: 1px;
`;

const Tracklist = () => {
  const [open, setOpen] = useState<boolean>(false);

  const currentIndex = useAppSelector((state) => state.app.currentIndex);
  const playlist = useAppSelector((state) => state.app.playlist);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Wrapper>
      {
        !open
          ? <ShowButton onClick={toggleOpen}>Tracklist +</ShowButton>
          : <>
            <ShowButton onClick={toggleOpen}>Tracklist -</ShowButton>
            <ScrollArea>
              {
                playlist.map((track, index) => <p
                  key={index}
                  className={index === currentIndex ? 'selected' : ''}
                >
                  { track.played && <span>&#x2713; </span> }
                  { track.number + '. ' + track.path.replace('/assets/audio/', '') }
                </p>)
              }
            </ScrollArea>
          </>
      }
    </Wrapper>
  );
};

export default Tracklist;
