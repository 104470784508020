import styled from 'styled-components';
import NoteButton from '../../components/UI/NoteButton';
import useAppDispatch from '../../hooks/useDispatch';
import { setPage } from '../../store/appSlice';

const Wrapper = styled.div`
  position: absolute;
  right: 25px;
  bottom: 25px;
  z-index: 100;
`;

const NoteLayout = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setPage('player'));
  };

  return (
    <Wrapper>
      <NoteButton
        onClick={handleClick}
      />
    </Wrapper>
  );
};

export default NoteLayout;
