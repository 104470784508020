import styled from 'styled-components';
import loading from '../../images/loading.svg';
import loadingBg1 from '../../images/loading_bg1.svg';
import loadingBg2 from '../../images/loading_bg2.svg';
import { LoadingBg1, LoadingImg, LoadingBg2 } from './style';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Loading = () => {
  return (
    <Wrapper>
      <LoadingImg src={loading} alt='loading...'/>
      <LoadingBg1 src={loadingBg1} alt=''/>
      <LoadingBg2 src={loadingBg2} alt=''/>
    </Wrapper>
  );
};

export default Loading;
