interface IProps {
  rx: string;
  className: string;
}

const PlayerRectRound = ({ rx, className }: IProps) => <svg className={className} width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect opacity="0.2" x="-0.18376" y="0.286668" width="39.2503" height="39.8056" rx={rx} transform="matrix(0.216813 0.976213 -0.977576 0.210584 36.5216 -1.63679)" stroke="url(#paint0_linear_4280_170242)" strokeWidth="0.483095"/>
  <defs>
    <linearGradient id="paint0_linear_4280_170242" x1="39.7334" y1="18.3428" x2="8.41872e-08" y2="18.3428" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FF665C"/>
      <stop offset="0.520833" stopColor="#5B2AC8"/>
    </linearGradient>
  </defs>
</svg>;

export default PlayerRectRound;
