import { ControlPanelText, ControlRightCore, WrapperControlRight } from './style';
import PlayerButton from '../../../components/UI/PlayerButton';
import { useState } from 'react';
import useAppSelector from '../../../hooks/useSelector';

const ControlRight = () => {
  const [active, setActive] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  const disabled = useAppSelector((state) => state.app.playlist.length) === 0;

  return (
    <WrapperControlRight
      disabled={disabled}
      active={active}
      hover={hover}
    >
      <ControlRightCore>
        <PlayerButton
          onMouseDown={() => setActive(true)}
          onMouseUp={() => setActive(false)}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          disabled={disabled}
          width='48px'
        >
          <ControlPanelText>View on OpenSea</ControlPanelText>
        </PlayerButton>
      </ControlRightCore>
    </WrapperControlRight>
  );
};

export default ControlRight;
